import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import { useRtn } from "../../../Application/hooks/useRtn";
import { AppLoader, Button, InvisibleButton } from "../../../Application/layout";
import { Toast, ToastType } from "../../../Application/layout/Toast/Toast";
import * as libraryEventTypes from "../../../Application/services/realTimeNotification/events/libraryEvents";
import { RStatus, RtnStatus, Sizes } from "../../../Application/types";
import { ConfirmationModal } from "../../../Discover/layout/ConfirmationModal/ConfirmationModal";
import {
  selectAssetsMap,
  selectAssets,
  selectFlow,
  selectFlowError,
  selectFlowStatus,
  selectProgress,
  selectSectionHeaders,
  selectSectionProgress,
  selectUpdateStatus,
} from "../../redux/flow/flowSelectors";
import { req, reset, restartFlow, skip, startProgressUpdate, startTransition } from "../../redux/flow/flowSlice";
import { AssetWithProgressFields, Progress } from "../../types";

import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { BackLink } from "../../../Application/layout/BackLink/BackLink";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import useCollapse from "../../hooks/useCollapse";
import { FlowPageWrapper } from "../../layout/FlowPageWrapper/FlowPageWrapper";
import { ErrorsHandler } from "../ErrorsHandler/ErrorsHandler";
import { FlowAssetView } from "../FlowAssetView/FlowAssetView";
import { FlowContents } from "../FlowContents/FlowContents";
import { getExtendedProgress } from "./helpers/extendedProgressHelpers";

import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";
import { useProgressEmitter } from "../../emitters/progressEventEmitter";
import { useProgressPolling } from "../../hooks/useProgressPolling";
import styles from "./FlowPage.module.scss";
import { getAssetKey } from "features/Flows/helpers/flowHelper";
import { FlowEntityType } from "features/common/types";
import { SkipObserver } from "../SkipObserver/SkipObserver";
import { TextTruncator } from "features/Application/layout/TextTruncator/TextTruncator";

interface Props {
  /* Let's describe props here */
}

export const FlowPage: React.FC<Props> = () => {
  const { t } = useTranslation(["flows", "events"]);
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false);
  const [isRestartSuccessToastVisible, setIsRestartSuccessToastVisible] = useState(false);
  const [isRegistrationSuccessToastVisible, setIsRegistrationSuccessToastVisible] = useState(false);
  const { state } = useLocation();
  let { flowId } = useParams();
  const dispatch = useDispatch();
  const { isMobileScreen } = useContext(BreakpointContext);
  const { collapsed, toggleCollapse } = useCollapse();
  const flow = useSelector(selectFlow);
  const assets = useSelector(selectAssets);
  const status = useSelector(selectFlowStatus);
  const flowError = useSelector(selectFlowError);
  const progress = useSelector(selectProgress);
  const updateStatus = useSelector(selectUpdateStatus);
  const assetsMap = useSelector(selectAssetsMap);
  const sectionHeaders = useSelector(selectSectionHeaders);
  const { records } = useSelector(selectSectionProgress) ?? { records: [] };
  const courses = useMemo(
    () => getExtendedProgress(assets, sectionHeaders, records),
    [assets, records, sectionHeaders],
  );

  const hasError = useMemo(() => status === RStatus.Error && flowError, [status, flowError]);

  const courseItems = useMemo(
    () => courses.reduce((acc, current) => [...acc, ...current.items], [] as AssetWithProgressFields[]),
    [courses],
  );

  const [subscribe, raise] = useProgressEmitter(Number(flowId));

  const handleProgressUpdated = useCallback(
    (payload: Progress) => {
      if (payload.flowId !== flowId) {
        return;
      }

      raise({ type: "ProgressUpdated", payload });
    },
    [flowId, raise],
  );
  const onRegistrationSuccess = useCallback(() => setIsRegistrationSuccessToastVisible(true), []);
  useRtn(libraryEventTypes.ClientUserSessionRegistrationSuccess, onRegistrationSuccess);

  useRtn(libraryEventTypes.ProgressUpdated, handleProgressUpdated);
  // id from the state blocks polling until the flow details are fetched
  useProgressPolling(flow?.flow.id, handleProgressUpdated);

  useEffect(() => {
    const resetUnsub = subscribe("ResetPerformed", () => {
      setIsRestartSuccessToastVisible(true);
    });
    const updateUnsub = subscribe("ProgressUpdated", payload => {
      dispatch(startProgressUpdate(payload));
    });
    return () => {
      resetUnsub();
      updateUnsub();
    };
  }, [dispatch, subscribe]);

  const onFetchFlow = useCallback(() => {
    if (flowId) {
      dispatch(req({ id: Number(flowId), startFlowActive: true }));
    }
  }, [dispatch, flowId]);

  useEffect(() => {
    if (flow && assets && progress) {
      let currentAsset = assets[progress.selectedAssetIndex];
      if (currentAsset) {
        document.title = `${flow.flow.title} | ${currentAsset.title}`;
        SendPageView();
      }
    }
  }, [flow, assets, progress.selectedAssetIndex, progress]);

  useEffect(() => {
    onFetchFlow();
  }, [onFetchFlow]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const openRestartModal = useCallback(() => {
    setIsRestartModalOpen(true);
  }, []);

  const closeRestartModal = useCallback(() => {
    setIsRestartModalOpen(false);
  }, []);

  const closeRestartSuccessToast = useCallback(() => {
    setIsRestartSuccessToastVisible(false);
  }, []);

  const submitRestartModal = useCallback(() => {
    if (flowId) {
      raise({ type: "ResetSubmitted" });
      dispatch(restartFlow({ id: Number(flowId) }));
    }

    closeRestartModal();
  }, [flowId, closeRestartModal, raise, dispatch]);

  const renderLoader = () => (
    <div className={styles.flowPageLoader}>
      <AppLoader />
    </div>
  );

  const skipAsset = () => {
    const asset = courseItems[progress.selectedAssetIndex];
    dispatch(startTransition({ assetOrder: asset.order }));
    dispatch(skip({ assetId: asset.id, assetType: asset.type }));
  };

  const getErrorCode = () => {
    const asset = courseItems[progress.selectedAssetIndex];
    return asset.type === FlowEntityType.UnavailableAsset
      ? ErrorType.Unavailable
      : assetsMap[getAssetKey(asset)]?.error?.code;
  };

  return (
    <FlowPageWrapper>
      <>
        {status === RStatus.Pending && renderLoader()}
        {hasError && <ErrorsHandler onClick={onFetchFlow} error={flowError!} />}
        {status === RStatus.Got && flow && (
          <>
            {(!collapsed || (!isMobileScreen && collapsed)) && (
              <section className={styles.pageTitle}>
                <BackLink to={"/discover"} state={{ prevPage: state }} hasText={isMobileScreen} />
                <div className={styles.header}>
                  {isMobileScreen && (
                    <InvisibleButton onClick={toggleCollapse} ariaLabel="toggle view">
                      <Icon data-testid="bars" className="bars" />
                    </InvisibleButton>
                  )}
                  <TextTruncator clamp={3} tooltipContent={flow.flow.title}>
                    <h1>{flow.flow.title}</h1>
                  </TextTruncator>
                </div>
                <Popup
                  content={t("restartCourse.popup.content")}
                  position="left center"
                  inverted
                  trigger={
                    <div className={styles.restartCourse} data-testid="restart course">
                      <InvisibleButton
                        onClick={openRestartModal}
                        ariaLabel="toggle restart"
                        data-testid="restart course button"
                      >
                        <Icon data-testid="undo alternate" className="undo alternate" fitted size={Sizes.Large} />
                      </InvisibleButton>
                    </div>
                  }
                />
              </section>
            )}
            <section className={styles.container}>
              <FlowContents
                contentVisible={collapsed}
                toggleContent={toggleCollapse}
                courses={courses}
                progress={progress}
              />
              <div className={styles.assetView}>
                {courseItems[progress.selectedAssetIndex] && (
                  <SkipObserver
                    isViewed={progress.viewedAssetIndexes.includes(progress.selectedAssetIndex)}
                    asset={courseItems[progress.selectedAssetIndex]}
                  >
                    <HttpErrorView
                      className={styles.assetError}
                      code={getErrorCode()}
                      message={assetsMap[getAssetKey(courseItems[progress.selectedAssetIndex])]?.error?.message}
                      action={skipAsset}
                    >
                      <FlowAssetView
                        isMobileScreen={isMobileScreen}
                        collapsed={collapsed}
                        asset={courseItems[progress.selectedAssetIndex]}
                        onRestart={openRestartModal}
                        progress={progress}
                      />
                    </HttpErrorView>
                  </SkipObserver>
                )}
                {updateStatus === RtnStatus.Pending && renderLoader()}
              </div>
            </section>
            <ConfirmationModal
              size={Sizes.Tiny}
              title={t("restartCourse.confirmModal.title")}
              body={t("restartCourse.confirmModal.body")}
              isOpen={isRestartModalOpen}
              handleClose={closeRestartModal}
              className={styles.restartModal}
              confirmButton={
                <Button onClick={submitRestartModal} data-testid="confirm-modal-confirm">
                  {t("restartCourse.confirmModal.button")}
                </Button>
              }
            />
            <Toast
              type={ToastType.Success}
              body={t("restartCourse.successToast.body")}
              visible={!hasError && isRestartSuccessToastVisible}
              onClose={closeRestartSuccessToast}
              autoDismiss
            />
            <Toast
              type={ToastType.Success}
              body={t("registration.successToast.body", {
                ns: "events",
              })}
              visible={isRegistrationSuccessToastVisible}
              onClose={() => setIsRegistrationSuccessToastVisible(false)}
              autoDismiss
            />
          </>
        )}
      </>
    </FlowPageWrapper>
  );
};
